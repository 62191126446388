import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Error from "./pages/Error";
import Kamaaina from "./pages/Kamaaina";
import Submarines from "./pages/Submarines";
import IslandPage from "./pages/IslandPage";
import About from "./pages/About";
import OurPurpose from "./pages/OurPurpose";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import TravelAgent from "./pages/TravelAgent";
import SalesPackets from "./pages/SalesPackets";
import Highlights from "./pages/Highlights";
import JoinUs from "./pages/JoinUs";
import Narration from "./pages/Narration";
import { waikikiProps, mauiProps, konaProps } from "./components/islandProps";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/submarines" element={<Submarines />} />
        <Route path="/waikiki" element={<IslandPage {...waikikiProps} />} />
        <Route path="/maui" element={<IslandPage {...mauiProps} />} />
        <Route path="/kona" element={<IslandPage {...konaProps} />} />
        <Route path="*" element={<Error />} />
        <Route path="/kamaaina" element={<Kamaaina />} />
        <Route path="/about" element={<About />} />
        <Route path="/our-mission" element={<OurPurpose />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/travel-agent" element={<TravelAgent />} />
        <Route path="/sales-packets" element={<SalesPackets />} />
        <Route path="/agency" element={<Highlights />} />
        <Route path="/employment" element={<JoinUs />} />
        <Route path="/kamaaina" element={<Kamaaina />} />
        <Route path="/narration" element={<Narration />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
