import Carousel from "react-bootstrap/Carousel";
import ReviewSlide from "../components/ReviewSlide";

export default function ReviewCarousel({ reviewsList }) {
  return (
    <Carousel indicators={false} pause={false} interval={null}>
      {reviewsList.map((review, i) => (
        <Carousel.Item key={`${review.title}-${i}`}>
          <ReviewSlide
            title={review.title}
            review={review.review}
            person={review.person}
            tour={review.tour}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
