import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import LeftRight from "../components/LeftRight";

function IslandPage({
  title,
  overlay,
  hero,
  left,
  icon1,
  icon2,
  icon3,
  bottom,
  h3,
  p1,
  icon1Text,
  icon2Text,
  icon3Text,
  link,
}) {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | {title}</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row position-relative heightVideo">
          <img src={hero} className="p-0 homeVidSizeNew" alt="hero" />
          <div className="homeTextOverlayVideo fw-semibold">{overlay}</div>
        </div>
        <LeftRight title={h3} left={left} p1={p1} thingsReal />
        <div className="row islandIcons justify-content-center py-5">
          <div className="col-12 col-md-4 col-lg-3 mb-4 text-center d-flex flex-column align-items-center">
            <img src={icon1} alt="icon1" />
            <p>{icon1Text}</p>
          </div>
          <div className="col-12 col-md-4 col-lg-3 mb-4 text-center d-flex flex-column align-items-center">
            <img src={icon2} alt="icon2" />
            <p>{icon2Text}</p>
          </div>
          <div className="col-12 col-md-4 col-lg-3 text-center d-flex flex-column align-items-center">
            <img src={icon3} alt="icon3" />
            <p>{icon3Text}</p>
          </div>
          <div className="col-12 text-center mt-5">
            <Button
              as={Link}
              to={link}
              className="booknow-cta text-nowrap iconsButtonFont"
            >
              BOOK A {overlay} DIVE NOW
            </Button>
          </div>
        </div>
      </div>
      <img src={bottom} alt="bottom" className="w-100 p-0 m-0" />
    </>
  );
}

export default IslandPage;
