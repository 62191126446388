import { Helmet } from "react-helmet";
import hero from "../images/purposeHero.jpg";
import steward from "../images/stewardship.jpg";
import purposeText from "../images/purposeText.png";

function OurPurpose() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Our Mission</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row">
          <img src={hero} className="p-0 aboutHero" alt="hero" />
        </div>
        <div
          className="row bg-white text-start justify-content-center"
          style={{ color: "#03286B" }}
        >
          <div className="col-12 col-md-10 col-xl-9 col-xxl-6 py-5">
            <p className="fw-bold mb-4 fs-2 text-center">
              ECO-FRIENDLY | EDUCATIONAL | EXCITING
            </p>
            <p className="mb-4">Aloha from Atlantis Adventures!</p>
            <p className="mb-4">
              When you join us for a tour you see and experience Hawaii in a way
              that's offered by no one else in the Hawaiian Islands.
            </p>
            <p className="mb-4">
              You take away memories for life and a stronger appreciation for
              protecting our precious ocean environment, a message Atlantis
              imparts to every guest, every day.
            </p>
            <p className="mb-4">
              <span className="fw-bold fs-4">Atlantis Submarines</span>
              <br />
              Our subs help guests explore the depths of Hawaii&#39;s ocean
              waters 100-feet below the surface and "swim" amidst schools of
              beautiful fish in the world's most technologically advanced
              passenger submarine.
            </p>
            <p className="mb-4">
              Atlantis has been sharing the magnificence of Hawaii's undersea
              world since 1988 using environmentally safe submarines that are
              battery powered, emit no pollutants, and quietly move through the
              water, disturbing no one. It's the ultimate in sustainable
              tourism. We've also taken on the responsibility of installing
              artificial reefs at some Hawaii dive sites, to create
              self-sustaining habitats for fish and marine life to flourish.
            </p>
            <p className="mb-4">
              <span className="fw-bold fs-4">Majestic by Atlantis Cruises</span>
              <br />
              Majestic is Atlantis's newest state-of-the-art cruise vessel,
              offering Waikiki Sunset Cocktail Cruises, Friday Fireworks &
              Cocktail Cruises, and Seasonal Whale Watch Cruises.
            </p>
            <p className="mb-0">
              Onboard Majestic, guests will enjoy Hawaii's newest yacht cruise
              experience, with the latest advancement in boat stabilization
              technology called Seakeeper, which ensures a more comfortable
              cruising experience.
            </p>
          </div>
          <div className="row justify-content-center px-0 pb-5">
            <div className="col-12 col-md-10 col-xl-9 col-xxl-6">
              <img
                src={steward}
                alt="stewardship"
                className="w-100 stewardBox"
              />
            </div>
          </div>
        </div>
        <div className="row purposeBG justify-content-center py-5">
          <div className="col-12 col-md-10 col-xl-7">
            <img src={purposeText} alt="purpose" className="w-100" />
          </div>
        </div>
      </div>
    </>
  );
}

export default OurPurpose;
