import waikikiBook from "../images/waikikiBook.jpg";
import mauiBook from "../images/mauiBook.jpg";
import konaBook from "../images/konaBook.jpg";
import cocktailBook from "../images/cocktailBook.jpg";
import fireworksBook from "../images/fireworksBook.jpg";
import whaleBook from "../images/whaleBook.jpg";
import BookNowCard from "./BookNowCard";
import { useLocation } from "react-router-dom";

export default function BookNow({ book, click }) {
  const location = useLocation();
  let bookLocationWaikiki;
  let bookLocationMaui;
  let bookLocationKona;
  let bookLocationCocktail;
  let bookLocationFireworks;
  let bookLocationWhale;

  if (location.pathname.includes("kamaaina")) {
    bookLocationWaikiki =
      "https://book.atlantisadventures.com/details/205302/atlantis-premium-submarine-tour-waikiki-kamaaina-discount";
    bookLocationMaui =
      "https://book.atlantisadventures.com/details/205329/atlantis-submarine-tour-maui-kamaaina-discount";
    bookLocationKona =
      "https://book.atlantisadventures.com/details/205340/atlantis-submarine-tour-kona-kamaaina-discount";
    bookLocationCocktail =
      "https://book.atlantisadventures.com/details/309586/waikiki-sunset-cocktail-cruise-kamaaina-discount";
    bookLocationFireworks =
      "https://book.atlantisadventures.com/details/317222/friday-fireworks-cocktail-cruise-kamaaina-discount";
    bookLocationWhale =
      "https://book.atlantisadventures.com/details/221534/whale-watch-cruise-in-waikiki-kamaaina-discount";
  }

  return (
    <div className={`chooseBook ${book}`}>
      <div className="container-lg">
        <div className="row justify-content-center center">
          <BookNowCard
            href={
              bookLocationWaikiki
                ? bookLocationWaikiki
                : "https://book.atlantisadventures.com/details/151625/atlantis-submarine-tour-in-waikiki-dropdown"
            }
            pic={waikikiBook}
            alt="book waikiki"
            text="Waikiki Underwater Adventure"
            click={click}
          />
          <BookNowCard
            href={
              bookLocationMaui
                ? bookLocationMaui
                : "https://book.atlantisadventures.com/details/151037/atlantis-submarine-tour-maui-dropdown"
            }
            pic={mauiBook}
            alt="book maui"
            text="Maui Underwater Adventure"
            click={click}
          />
          <BookNowCard
            href={
              bookLocationKona
                ? bookLocationKona
                : "https://book.atlantisadventures.com/details/154563/atlantis-submarine-tour-kona-dropdown"
            }
            pic={konaBook}
            alt="book kona"
            text="Kona Underwater Adventure"
            click={click}
          />
        </div>
        <div className="row justify-content-center center">
          <BookNowCard
            href={
              bookLocationCocktail
                ? bookLocationCocktail
                : "https://book.atlantisadventures.com/details/204130/waikiki-sunset-cocktail-cruise-dropdown/?promo=20sunsetwebsite"
            }
            pic={cocktailBook}
            alt="book cocktail cruise"
            text="Waikiki Sunset Cocktail Cruise"
            click={click}
          />
          <BookNowCard
            href={
              bookLocationFireworks
                ? bookLocationFireworks
                : "https://book.atlantisadventures.com/details/205347/friday-fireworks-cocktail-cruise-dropdown"
            }
            pic={fireworksBook}
            alt="book fireworks"
            text="Friday Fireworks Cruise"
            click={click}
          />
          <BookNowCard
            href={
              bookLocationWhale
                ? bookLocationWhale
                : "https://book.atlantisadventures.com/details/154748/whale-watch-cruise-in-waikiki-dropdown"
            }
            pic={whaleBook}
            alt="book whale watch"
            text="Whale Watch Cruise in Waikiki"
            click={click}
          />
        </div>
      </div>
    </div>
  );
}
