export default function VideoFrame({ video, overlay, img }) {
  return (
    <div className="row position-relative heightVideo">
      <video
        src={video}
        autoPlay
        loop
        muted
        playsInline
        className="p-0 homeVidSizeNew"
      />
      {overlay && !img && (
        <div className="homeTextOverlayVideo">EXPLORE OUR HAWAII</div>
      )}
      {!overlay && img && (
        <img
          src={img}
          className="homeTextOverlayVideo subsOverlay"
          alt="locations logo"
        />
      )}
    </div>
  );
}
