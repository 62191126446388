const oahuJobsList = [
  {
    title: "Automotive Paint & Body Repair Expert",
    description:
      "Our Honolulu Maintenance Department has an immediate need of someone who is skilled and experienced in automotive painting and body repair. Full-time work schedule -- 4 days on followed by 3 days off. Schedule is 6 am - 5 pm, but  may vary, depending on business needs. Work location is Pier 27 in Honolulu. Pay rate is $28-$30 per hour, depending on skills and experience.",
  },
  {
    title: "Shipwright",
    description:
      "Our Honolulu Maintenance Department has an immediate need for a Shipwright. Must have at least 5 years of experience in fiberglass and boat repair/fabrication. Automotive/industrial painting experience also desirable. Full-time work schedule at night, but hours may vary. Work location is Pier 27 in Honolulu. Pay rate is $30-$35 per hour, depending on skills and experience.",
  },
  {
    title: "Part-time Boat Captain",
    description:
      "Atlantis Cruises has an immediate opportunity for a part-time Boat Captain with a 100-ton USCG license to work as a captain on our beautiful cocktail cruise vessel The Majestic at Pier 6 in Honolulu. Pay rate is $30 per hour. Must have experience as a captain for a 100-ton or larger vessel. Previous tourism/hospitality experience desirable. Fun job, fun environment, and excellent benefits!",
  },
  {
    title: "Marine Maintenance Technician",
    description:
      "Position requires strong mechanical ability and very good aptitude with tools. Work schedule is 5pm - 3am, 4 nights on followed by 3 nights off. Work location is Pier 27 in Honolulu (703 N. Nimitz Highway). Pay rate will be $25-$26 per hour.",
  },
  {
    title: "Majestic Senior Captain",
    description:
      "Atlantis Cruises has an immediate need for a full-time Senior Captain who will oversee all operations of our vessel, Majestic. Position will report to our Majestic General Manager. Must have a 100-ton USCG license. A TWIC card is desirable. The schedule will include evening hours, weekends, and holidays. We need someone who is professional, courteous, safety conscious, and comfortable working on a moving vessel. Previous ocean and boating experience is required. We also need someone who is a skilled and effective leader and who can competently manage all operations of the Majestic. Full-time salary of $79,000 per year.",
  },
];

export default oahuJobsList;
