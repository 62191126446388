import { MdOutlineStar } from "react-icons/md";

function ReviewSlide({ title, review, person, tour }) {
  return (
    <div className="col-11 col-lg-9 col-xxl-7 mx-auto text-light">
      <h2 className="mb-3 lh-1">
        <MdOutlineStar /> <MdOutlineStar /> <MdOutlineStar /> <MdOutlineStar />{" "}
        <MdOutlineStar />
      </h2>
      <p
        className={`lh-sm text-uppercase fs-3 fw-bold ${
          tour ? "mb-0" : "mb-4"
        }`}
      >
        {title}
      </p>
      {tour && <p className="fs-4 mb-4 lh-sm">({tour})</p>}

      <p className="fs-3 mb-0">
        {review}
        <br />- {person}
      </p>
    </div>
  );
}

export default ReviewSlide;
