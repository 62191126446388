import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function SalesCard({
  pic,
  title,
  name,
  position,
  phone,
  email,
  button,
  link,
}) {
  return (
    <>
      <div
        className="row py-5 justify-content-center"
        style={{ color: "#03286B" }}
      >
        <div className="col-6 col-md-6 col-lg-4 col-xxl-3 ps-0 pe-lg-5">
          <img src={pic} alt="profile" className="w-100 mb-4 mb-md-0" />
        </div>

        <div className="col-6 col-md-6 col-lg-4 col-xxl-3 text-start p-0">
          <div>
            <p className="fs-3 lh-sm fw-bold mb-4">{title}</p>
            <p>
              <span className="fs-5 fw-bold">{name}</span>
              <br />
              <span>{position}</span>
            </p>
            <p className="mb-4">
              <b>Phone:</b>{" "}
              <a className="text-decoration-none" href={`tel:${phone}`}>
                {phone}
              </a>
              <br />
              <b>Email:</b>{" "}
              <a
                className="text-decoration-none text-break"
                href={`mailto:${email}@atlantisadventures.com`}
              >
                {email}@atlantisadventures.com
              </a>
            </p>
          </div>

          <Button
            as={Link}
            className="salesButton mt-auto mx-0 salesDesktop"
            to={link}
          >
            {button} SALES PACKETS
            <br />
            Fact Sheets, Images, Videos
          </Button>
        </div>
        <Button
          as={Link}
          className="salesButton mt-auto mx-0 salesMobile"
          to={link}
        >
          {button} SALES PACKETS
          <br />
          Fact Sheets, Images, Videos
        </Button>
      </div>
    </>
  );
}
