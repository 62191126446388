import { Helmet } from "react-helmet";
import hero from "../images/narrationHero.jpg";
import LeftRight from "../components/LeftRight";
import narrationWaikiki from "../images/narrationWaikiki.jpg";
import narrationMaui from "../images/narrationMaui.jpg";
import narrationKona from "../images/narrationKona.jpg";
import waikikiEnglish from "../audio/waikikiEnglish.mp3";
import waikikiJapanese from "../audio/waikikiJapanese.mp3";
import waikikiKorean from "../audio/waikikiKorean.mp3";
import waikikiMandarin from "../audio/waikikiMandarin.mp3";
import waikikiSpanish from "../audio/waikikiSpanish.mp3";
import mauiJapanese from "../audio/mauiJapanese.mp3";
import mauiKorean from "../audio/mauiKorean.mp3";
import mauiMandarin from "../audio/mauiMandarin.mp3";
import mauiSpanish from "../audio/mauiSpanish.mp3";
import konaEnglish from "../audio/konaEnglish.mp3";
import konaSpanish from "../audio/konaSpanish.mp3";
import konaMandarin from "../audio/konaMandarin.mp3";
import konaJapanese from "../audio/konaJapanese.mp3";
import konaKorean from "../audio/konaKorean.mp3";

function Kamaaina() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Narration Downloads</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row">
          <img src={hero} className="p-0 aboutHero" alt="hero" />
        </div>
        <div
          className="row bg-light justify-content-center"
          style={{ color: "#03286B" }}
        >
          <div className="col-12 col-md-10 col-xl-9 col-xxl-6 py-5">
            <p className="fs-2 fw-bold mb-4 text-center">NARRATION</p>
            <p className="mb-4">
              <span className="fs-5">ABOUT TOUR DOWNLOADS</span>
              <br />
              Learn about Hawaii's marine environment with Atlantis Submarines.
              Choose from one of the selections listed below and simply download
              our tour narration in your preferred language to your phone or
              tablet.
            </p>
            <p className="mb-4">
              Be sure to download your audio tour before your submarine
              adventure since there is no Wi-Fi as we dive over 100 feet beneath
              the sea. Mahalo for joining us on a journey to the ocean floor.
              Don't forget your earbuds or headphones!
            </p>
            <span className="fs-5">LANGUAGES AVAILABLE</span>
            <ul>
              <li>ENGLISH</li>
              <li>JAPANESE</li>
              <li>KOREAN</li>
              <li>MANDARIN</li>
              <li>SPANISH</li>
            </ul>
            <p className="fs-5 mb-0">
              DON'T FORGET TO BRING YOUR EARBUDS OR HEADPHONES!
            </p>
          </div>
        </div>
        <LeftRight
          left={narrationWaikiki}
          title="ATLANTIS SUBMARINES WAIKIKI"
          narration
          en={waikikiEnglish}
          downloadEn="waikikiEnglish.mp3"
          jp={waikikiJapanese}
          downloadJp="waikikiJapanese.mp3"
          kr={waikikiKorean}
          downloadKr="waikikiKorean.mp3"
          ch={waikikiMandarin}
          downloadCh="waikikiMandarin.mp3"
          sp={waikikiSpanish}
          downloadSp="waikikiSpanish.mp3"
        />
        <LeftRight
          left={narrationMaui}
          title="ATLANTIS SUBMARINES MAUI"
          narration
          jp={mauiJapanese}
          downloadJp="mauiJapanese.mp3"
          kr={mauiKorean}
          downloadKr="mauiKorean.mp3"
          ch={mauiMandarin}
          downloadCh="mauiMandarin.mp3"
          sp={mauiSpanish}
          downloadSp="mauiSpanish.mp3"
          changeBG
        />
        <LeftRight
          left={narrationKona}
          title="ATLANTIS SUBMARINES KONA"
          narration
          en={konaEnglish}
          downloadEn="konaEnglish.mp3"
          jp={konaJapanese}
          downloadJp="konaJapanese.mp3"
          kr={konaKorean}
          downloadKr="konaKorean.mp3"
          ch={konaMandarin}
          downloadCh="konaMandarin.mp3"
          sp={konaSpanish}
          downloadSp="konaSpanish.mp3"
        />
      </div>
    </>
  );
}

export default Kamaaina;
