import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import logo from "../images/atlantis-logo.png";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FiChevronDown } from "react-icons/fi";
import BookNow from "./BookNow";

function Header() {
  const bookNowRef = useRef(null);
  const [englishPosition, setEnglishPosition] = useState({ right: "0px" });
  const [navbarBackground, setNavbarBackground] = useState("transparent");
  const [logoSize, setLogoSize] = useState("navLogo");
  const [changeHeight, setChangeHeight] = useState("110px");
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const [book, setBook] = useState("bookHide");
  const [language, setLanguage] = useState(false);

  function clickLink() {
    setBook("bookHide");
  }

  useEffect(() => {
    const updatePosition = () => {
      if (bookNowRef.current) {
        const rect = bookNowRef.current.getBoundingClientRect();
        if (window.innerWidth < 992) {
          setEnglishPosition({ right: "10px" }); // Fixed position for smaller screens
        } else {
          setEnglishPosition({ right: `${window.innerWidth - rect.right}px` });
        }
      }
    };

    const handleGlobalClick = (event) => {
      if (!event.target.closest(".homeBookButton") && book === "bookShow") {
        setBook("bookHide");
      }

      if (!event.target.closest(".englishLanguage")) {
        setLanguage(false);
      }
    };

    const trackPositionDuringTransition = () => {
      updatePosition();
      requestAnimationFrame(trackPositionDuringTransition);
    };

    trackPositionDuringTransition();

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBackground("rgba(50, 50, 50,.6");
        setChangeHeight("70px");
        setLogoSize("navLogoSmall");
      } else {
        setNavbarBackground("transparent");
        setLogoSize("navLogo");
        setChangeHeight("110px");
      }
    };

    document.addEventListener("click", handleGlobalClick);

    handleScroll();
    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleGlobalClick);
    };
  }, [book]);

  const location = useLocation();
  const isHome = location.pathname === "/";
  let bookLocation = "";

  if (location.pathname.includes("waikiki")) {
    bookLocation =
      "https://book.atlantisadventures.com/details/151625/atlantis-submarine-tour-in-waikiki";
  }
  if (location.pathname.includes("maui")) {
    bookLocation =
      "https://book.atlantisadventures.com/details/151037/atlantis-submarine-tour-maui";
  }
  if (location.pathname.includes("kona")) {
    bookLocation =
      "https://book.atlantisadventures.com/details/154563/atlantis-submarine-tour-kona";
  }

  const handleCloseOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  function handleClick() {
    setBook((prev) => (prev === "bookHide" ? "bookShow" : "bookHide"));
  }

  return (
    <>
      <BookNow book={book} click={clickLink} />

      {/* For top ticker => CSS: navPOS on, languageBar top on*/}
      {/* <div
        className="position-fixed vw-100 text-center ticker"
        style={{ backgroundColor: "#124789", zIndex: "100" }}
      >
        <p className="text-white fs-5 fst-italic lh-sm bannerText px-1 pt-2 pt-sm-0 pb-0">
          Explore Our New Year's Eve Cruise{" "}
          <a
            style={{ color: "#fff" }}
            href="https://book.atlantisadventures.com/details/323407/majestic-new-years-eve-cruise"
          >
            Click for Details
          </a>
        </p>
      </div> */}
      <div
        className="languageBar position-fixed vw-100 d-flex justify-content-end"
        style={{
          paddingRight: englishPosition.right,
        }}
      >
        {!isHome && (
          <a
            className="p-0 text-decoration-none position-relative pe-4 pe-md-5"
            href="https://www.majestichawaii.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            MAJESTICHAWAII.COM
          </a>
        )}
        <Button
          className="lh-sm p-0 text-decoration-none position-relative englishLanguage"
          variant="link"
          onClick={() => setLanguage(!language)}
        >
          ENGLISH <FiChevronDown style={{ marginBottom: "2px" }} />
        </Button>
        {language && (
          <div
            className="position-absolute jpBar"
            style={{
              top: "25px",
            }}
          >
            <Button
              className="pt-0 text-decoration-none"
              variant="link"
              href="https://jp.atlantisadventures.com"
              style={{ padding: "0 24px 2px" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              日本語
            </Button>
          </div>
        )}
      </div>
      <Navbar
        collapseOnSelect
        variant="dark"
        sticky="top"
        expand="lg"
        className="position-fixed vw-100 text-center navPos"
        style={{
          backgroundColor: navbarBackground,
          height: changeHeight,
        }}
      >
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setOffcanvasOpen(true)}
        />
        <Navbar.Brand
          className="position-absolute start-50 navbar-brand-secondary translate-middle-x m-0 p-0"
          as={Link}
          to="/"
        >
          <img src={logo} className="navLogo-secondary" alt="logo" />
        </Navbar.Brand>
        {bookLocation ? (
          <Button
            ref={bookNowRef}
            className="booknow-secondary text-nowrap mobileBookNow"
            href={bookLocation}
          >
            BOOK NOW
          </Button>
        ) : (
          <Button
            ref={bookNowRef}
            className="booknow-secondary text-nowrap mobileBookNow thisIsBook homeBookButton"
            onClick={handleClick}
          >
            BOOK NOW
          </Button>
        )}
        <Navbar.Offcanvas
          show={offcanvasOpen}
          onHide={handleCloseOffcanvas}
          id="responsive-navbar-nav"
        >
          <Offcanvas.Header
            data-bs-theme="dark"
            closeButton
            className="btn-close-white"
          ></Offcanvas.Header>
          <Nav className="justify-content-start justify-content-lg-evenly align-items-lg-center mt-5 mt-lg-0 mx-4 mx-lg-0">
            <Navbar.Brand className="navbar-brand-main" as={Link} to="/">
              <img src={logo} className={logoSize} alt="logo" />
            </Navbar.Brand>
            {isHome ? (
              <>
                <NavDropdown
                  title={
                    <>
                      <span>ATLANTIS SUBMARINES</span>{" "}
                      <FiChevronDown style={{ marginBottom: "2px" }} />
                    </>
                  }
                  id="submarine-dives-dropdown"
                  className="text-nowrap navFontSize my-1 my-lg-0"
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/waikiki"
                    onClick={() => handleCloseOffcanvas()}
                  >
                    Waikiki Dive
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/maui"
                    onClick={() => handleCloseOffcanvas()}
                  >
                    Maui Dive
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/kona"
                    onClick={() => handleCloseOffcanvas()}
                  >
                    Kona Dive
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title={
                    <>
                      <span>MAJESTIC BY ATLANTIS CRUISES</span>{" "}
                      <FiChevronDown style={{ marginBottom: "2px" }} />
                    </>
                  }
                  id="majestic-cruises-dropdown"
                  className="text-nowrap navFontSize my-1 my-lg-0"
                >
                  <NavDropdown.Item
                    as={Link}
                    to="https://www.majestichawaii.com"
                    onClick={() => handleCloseOffcanvas()}
                  >
                    Waikiki Sunset Cocktail Cruise
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="https://www.majestichawaii.com/friday-fireworks-cocktail-cruise"
                    onClick={() => handleCloseOffcanvas()}
                  >
                    Friday Fireworks & Cocktail Cruise
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="https://www.majestichawaii.com/whalewatch"
                    onClick={() => handleCloseOffcanvas()}
                  >
                    Whale Watch Cruise
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <>
                <Nav.Link
                  as={Link}
                  eventKey="1"
                  to="/waikiki"
                  className="text-nowrap navFontSize my-1 my-lg-0"
                  onClick={() => handleCloseOffcanvas()}
                >
                  WAIKIKI
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  eventKey="2"
                  to="/maui"
                  className="text-nowrap navFontSize my-1 my-lg-0"
                  onClick={() => handleCloseOffcanvas()}
                >
                  MAUI
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  eventKey="3"
                  to="/kona"
                  className="text-nowrap navFontSize my-1 my-lg-0"
                  onClick={() => handleCloseOffcanvas()}
                >
                  KONA
                </Nav.Link>
              </>
            )}
            {bookLocation ? (
              <Button
                ref={bookNowRef}
                className="booknow-main text-nowrap navFontSize"
                href={bookLocation}
              >
                BOOK NOW
              </Button>
            ) : (
              <Button
                ref={bookNowRef}
                className="booknow-main text-nowrap navFontSize thisIsBook homeBookButton"
                onClick={handleClick}
              >
                BOOK NOW
              </Button>
            )}
          </Nav>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
}

export default Header;
