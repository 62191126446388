import { Helmet } from "react-helmet";
import hero from "../images/aboutHero.jpg";
import left from "../images/2 Waikiki.jpg";
import bottom from "../images/aboutBottom.jpg";
import history from "../images/aboutHistory.jpg";
import LeftRight from "../components/LeftRight";

function About() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | About Us</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row">
          <img src={hero} className="p-0 aboutHero" alt="hero" />
        </div>
        <div
          className="row bg-light text-start justify-content-center"
          style={{ color: "#03286B" }}
        >
          <div className="col-12 col-md-10 col-xl-9 col-xxl-6 py-5">
            <p className="fs-2 fw-bold mb-4 text-center">
              THE STORY OF ATLANTIS SUBMARINES
            </p>
            <p className="mb-4">
              Dennis Hurd is the president of Atlantis Submarines International,
              Inc., the company responsible for taking more than 11 million
              passengers on undersea adventures aboard its 48 and 64 passenger
              submarines. A fleet larger than that of many national navies,
              Atlantis subs are now found in Grand Cayman, Barbados, St. Thomas,
              Aruba, Guam, Cozumel, and on the Hawaii Islands of Hawaii, Maui
              and Oahu.
            </p>
            <p className="mb-4">
              The Atlantis XIV, which operates off Waikiki Beach, Hawaii,
              accommodates 64 passengers, spans more than 100 feet and is the
              world's largest passenger submarine. Hurd launched his first
              battery-powered, nonpolluting Atlantis Submarine in December 1985
              off the coast of the Cayman Islands. The high-tech nature of the
              vessel, the air-conditioned, pressure-controlled cabin, and the
              underwater beauty of the reef made the attraction an instant hit.
              A design engineer who once captained a 20-ton schooner from Nova
              Scotia to the Bahamas, Hurd came up with the idea of recreational
              submarines during his tenure at International Hydrodynamics
              ("Hyco").
            </p>
            <p className="mb-0">
              At the time, Hyco was the world leader in deep-sea manned
              submersibles and the developer of submersibles that could tend
              North Sea oil rigs to depths of 6,600 feet. In the 1970's, Hurd
              would often take clients down in these submarines to inspect
              drilling sites. The thrill these executives got from subsea
              exploration was enough to make him think seriously about
              underwater tourism. During the slow winter months of 1977, Hurd
              studied the possibility of taking small submersibles into the
              Bahamas, but quickly discovered that a vehicle with capacity far
              more than one or two passengers would be a more financially viable
              option. From 1979-1983, Hurd ran his own worldwide service
              company, Offshore Engineering Corporation, which operated the
              small oil-industry subs. In 1983, he used the profits from that
              successful venture to start what is now the Atlantis Submarines
              organization.
            </p>
          </div>
        </div>
        <LeftRight
          left={left}
          title="THINGS GET REAL!"
          p1="Beginning with $250,000 in design and development money from
                friends and business acquaintances, Hurd completed preliminary
                drawings and market studies. He then went on to raise the
                millions necessary to build Atlantis I. Atlantis I was based on
                15 years prior experience in the research and commercial
                submersible industry including extensive experience in both the
                design and operational aspects of the technology. Atlantis I was
                purpose built to carry 28 passenger on hour long trips to tour
                coral reefs in tropical resort locations — a way for all people
                to experience the underwater world previously the reserve of
                scuba divers. Atlantis I would dive to 150 feet in air
                conditioned comfort and with no pressure on the passengers.
                Precise maneuvering would tour guests close to the reefs, viewed
                from large windows which were customer designed, built and
                tested by Atlantis."
          thingsReal
        />
      </div>
      <img src={bottom} alt="bottom" className="w-100 p-0 m-0" />
      <img src={history} alt="history" className="w-100 p-0 m-0" />
    </>
  );
}

export default About;
