import { Helmet } from "react-helmet";
import hero from "../images/salesTeamHero.jpg";
import margie from "../images/margie.jpg";
import shintaro from "../images/shintaro.jpg";
import SalesCard from "../components/SalesCard";

function SalesPackets() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Sales Packets</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row">
          <img src={hero} className="p-0 aboutHero" alt="hero" />
        </div>
        <div
          className="row pt-5 bg-white text-center justify-content-center"
          style={{ color: "#03286B" }}
        >
          <p className="fs-2 fw-bold">ATLANTIS SALES TEAM</p>
          <SalesCard
            pic={margie}
            title={
              <>
                Atlantis Submarines{" "}
                <span className="text-nowrap">Waikiki &</span>
                <br />
                Majestic by Atlantis Cruises
              </>
            }
            name="MARGIE LEHMAN"
            position="Director of Oahu Sales"
            phone="808-228-4155"
            email="mlehman"
            button="OAHU"
            link="https://drive.google.com/folderview?id=0B59kuuu0LZ90cHVya0pqSkQzaDA&resourcekey=0-8Dly3JKHsA4MY6038DMEeg"
          />
          <hr className="salesHR m-auto" />
          <SalesCard
            pic={shintaro}
            title="Atlantis Submarines Kona"
            name="SHINTARO HIRANO"
            position="Kona Sales Manager"
            phone="808-329-3175 ext 0133"
            email="shirano"
            button="KONA"
            link="https://drive.google.com/folderview?id=0B59kuuu0LZ90bmU4ZVlVa3Vzcm8&resourcekey=0-jyIO5DeYVaVpHcXtXmVL7A"
          />
        </div>
      </div>
    </>
  );
}

export default SalesPackets;
