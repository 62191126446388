import { Helmet } from "react-helmet";
import hero from "../images/joinHero.jpg";
import left from "../images/leftJoin.jpg";
import LeftRight from "../components/LeftRight";
import oahuJobsList from "../components/oahuJobs";
// import mauiJobsList from "../components/mauiJobs";
import konaJobsList from "../components/konaJobs";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";
import JobsCard from "../components/JobsCard";
import { FaRegWindowClose } from "react-icons/fa";

function JoinUs() {
  const form = useRef();
  const attach = useRef();

  function removeAttach() {
    attach.current.value = "";
  }

  function addJob(e) {
    setJob(e);
    setErrorJob("formInput");
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [job, setJob] = useState("");
  const [about, setAbout] = useState("");
  const [errorName, setErrorName] = useState("formInput");
  const [errorEmail, setErrorEmail] = useState("formInput");
  const [errorPhone, setErrorPhone] = useState("formInput");
  const [errorJob, setErrorJob] = useState("formInput");
  const [errorAbout, setErrorAbout] = useState("formInput");
  const [waitMessage, setWaitMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      setErrorName("errorFormInput");
    } else setErrorName("formInput");
    if (!email) {
      setErrorEmail("errorFormInput");
    } else setErrorEmail("formInput");
    if (!phone) {
      setErrorPhone("errorFormInput");
    } else setErrorPhone("formInput");
    if (!job) {
      setErrorJob("errorFormInput");
    } else setErrorJob("formInput");
    if (!about) {
      setErrorAbout("errorFormInput");
    } else setErrorAbout("formInput");

    if (name && email && phone && job && about) {
      setWaitMessage("Please wait...");

      emailjs
        .sendForm(
          "service_8kmbiu2",
          "template_i4ur4x9",
          form.current,
          "lIvuQcS29W-1LzLJc"
        )
        .then(
          function (response) {
            setName("");
            setEmail("");
            setPhone("");
            setJob("");
            setAbout("");
            removeAttach();
            setWaitMessage("Your message has been sent.");
            console.log("SUCCESS!", response.status, response.text);
          },
          function (err) {
            console.log("FAILED...", err);
            setWaitMessage(
              "There was an error. Please reload the page and try again"
            );
          }
        );
    } else {
      setWaitMessage("Required field(s) missing. Please check form.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Employment</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row">
          <img src={hero} className="p-0 aboutHero" alt="hero" />
        </div>
        <LeftRight
          left={left}
          title="ATLANTIS EMPLOYMENT OPPORTUNITIES IN HAWAII"
          p1={
            <>
              Atlantis has the following full-time opportunities available on
              Oahu, Maui, and on the Big Island of Hawaii. Our benefits include
              health and dental insurance, life insurance, long-term disability
              insurance, holiday pay, paid personal leave, and a 401(k) Plan.
              <span className="rem1" />
              You may apply on our website (please attach a copy of your resume)
              or send your resume to{" "}
              <a className="text-white" href="mailto:hr@atlantisadventures.com">
                hr@atlantisadventures.com
              </a>
              .
              <span className="rem1" />
              Work authorization for the USA is required. Please also note that
              we are unable to assist with relocation to Hawaii.
              <span className="rem1" />
              If you are looking for an exciting career opportunity in the
              travel and tourism industry, love to work with people, and are
              fascinated by working under the sea, we invite you to apply.
              <span className="rem1" />
              For behind the scenes videos and more information about working on
              the sea, please visit our Atlantis Adventures{" "}
              <a
                className="text-white"
                href="https://facebook.com/atlantishawaii"
              >
                Facebook
              </a>{" "}
              or{" "}
              <a
                className="text-white"
                href="https://instagram.com/atlantishawaii"
              >
                Instagram
              </a>
              .
            </>
          }
          thingsReal
        />
      </div>
      <div className="container">
        <div
          id="0"
          className="row text-center justify-content-evenly py-5"
          style={{ color: "#03286B" }}
        >
          <p className="fs-2 fw-bold mb-5">JOIN OUR TEAM</p>
          <div className="col-12 order-2 col-md-6 order-md-1 col-lg-6 text-start">
            <JobsCard place="Oahu" list={oahuJobsList} addJob={addJob} />
            <hr className="salesHR m-auto my-5" />
            {/* <JobsCard place="Maui" list={mauiJobsList} addJob={addJob} /> */}
            <JobsCard place="Kona" list={konaJobsList} addJob={addJob} />
          </div>
          <div className="col-12 order-1 col-md-6 order-md-2 col-lg-5 center pb-5 ">
            <h3 className="jobFormHeader fw-semibold">
              Employment Contact Form
            </h3>
            <div className="jobForm">
              <form ref={form} id="jobsForm" onSubmit={onSubmit}>
                <div className="container-fluid">
                  <div className="row justify-content-center pt-3">
                    <div className="col-6">
                      <input
                        className={errorName}
                        value={name}
                        name="name"
                        type="text"
                        placeholder="Full Name"
                        onChange={(e) => {
                          setName(e.target.value);
                          setErrorName("formInput");
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className={errorEmail}
                        value={email}
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrorEmail("formInput");
                        }}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <input
                        className={errorPhone}
                        value={phone}
                        name="phone"
                        type="text"
                        placeholder="Phone Number"
                        onChange={(e) => {
                          setPhone(e.target.value);
                          setErrorPhone("formInput");
                        }}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <select
                        className={errorJob}
                        value={job}
                        name="job"
                        onChange={(e) => {
                          setJob(e.target.value);
                          setErrorJob("formInput");
                        }}
                      >
                        <option value="" disabled>
                          Job Inquiring About
                        </option>
                        {oahuJobsList.map((job) => {
                          return newFunction(job, "Oahu");
                        })}
                        {/* {mauiJobsList.map((job) => {
                            return newFunction(job, "Maui");
                          })} */}
                        {konaJobsList.map((job) => {
                          return newFunction(job, "Kona");
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <textarea
                        rows="8"
                        className={errorAbout}
                        value={about}
                        name="about"
                        placeholder="About Yourself"
                        onChange={(e) => {
                          setAbout(e.target.value);
                          setErrorAbout("formInput");
                        }}
                      />
                    </div>
                  </div>
                  <div className="row pb-3">
                    <p className="text-start fw-bold">
                      Please attach your resume
                    </p>

                    <div className="row" style={{ paddingBottom: "5px" }}>
                      <div className="attachmentField col-10">
                        <input
                          ref={attach}
                          type="file"
                          name="attach"
                          id="resume"
                        />
                      </div>
                      <div className="col-2 d-flex justify-content-start align-items-center">
                        <FaRegWindowClose size="24px" onClick={removeAttach} />
                      </div>
                    </div>
                  </div>
                  {waitMessage ? (
                    <p className="waitMessage">{waitMessage}</p>
                  ) : (
                    ""
                  )}
                  <Button
                    className="jobFormButton"
                    variant="dark"
                    type="submit"
                  >
                    Send
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function newFunction(job, jobIsland) {
    return (
      <option key={job.title} value={`${jobIsland}: ${job.title}`}>
        {jobIsland}: {job.title}
      </option>
    );
  }
}

export default JoinUs;
