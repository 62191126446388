import { FaMapMarkerAlt } from "react-icons/fa";

export default function ContactCard({
  title,
  phone,
  phone2,
  email,
  address1,
  address2,
  map1,
  map2,
  hours,
  last,
}) {
  return (
    <div className="my-4">
      <p className="fs-3 mb-3 fw-bold lh-sm">{title}</p>
      <p>
        <strong>Phone:</strong> <a href="tel:18003810237">1-800-381-0237</a>{" "}
        or <a href={`tel:${phone2}`}>{phone2}</a>
      </p>
      {email && (
        <p>
          <strong>Email:</strong> <a href={`mailto:${email}`}>{email}</a>
        </p>
      )}
      <p className="mb-0">
        <strong>Address:</strong> {address1}
        <br />
        {map1 && (
          <a target="_blank" rel="noopener noreferrer" href={map1}>
            Google Map <FaMapMarkerAlt />
          </a>
        )}
      </p>
      {address2 && (
        <p className="mt-3">
          {address2}
          <br />
          <a target="_blank" rel="noopener noreferrer" href={map2}>
            Google Map <FaMapMarkerAlt />
          </a>
        </p>
      )}
      {hours && (
        <p className="mt-3 mb-0">
          <strong>Hours:</strong> {hours}
        </p>
      )}
      {!last && <hr className="contactHR" />}
    </div>
  );
}
