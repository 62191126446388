import { Helmet } from "react-helmet";
import hero from "../images/turtleHero.jpg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

function TravelAgent() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Travel Agent</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row">
          <img src={hero} className="p-0 aboutHero" alt="hero" />
        </div>
        <div
          className="row pt-5 bg-white text-center justify-content-center"
          style={{ color: "#03286B" }}
        >
          <p className="fs-2 fw-bold mb-4">SALES SUPPORT</p>
          <div className="col-12">
            <Button
              as={Link}
              className="salesButton mb-4 noMb"
              to="/sales-packets"
            >
              SALES PACKETS
              <br />
              Fact Sheets, Images, Videos
            </Button>
            <Button as={Link} className="salesButton" to="/agency">
              ATLANTIS ADVENTURES
              <br />
              TOUR HIGHLIGHTS
            </Button>
            <hr className="salesHR my-5 m-auto" />
          </div>
        </div>
        <div
          className="row bg-white text-center justify-content-center"
          style={{ color: "#03286B" }}
        >
          <p className="fs-2 fw-bold mb-4">LIVE INVENTORY & BOOKING</p>
          <div className="col-12">
            <Button
              as={Link}
              className="salesButton mb-4"
              to="https://book.atlantisadventures.com/details/151625/atlantis-submarine-tour-in-waikiki/?promo=travelagent"
            >
              OAHU SUBMARINES
            </Button>

            <Button
              as={Link}
              className="salesButton mb-4"
              to="https://book.atlantisadventures.com/details/204130/waikiki-sunset-cocktail-cruise/?promo=travelagent"
            >
              OAHU MAJESTIC CRUISES
            </Button>
          </div>
        </div>
        <div
          className="row bg-white text-center justify-content-center mb-5"
          style={{ color: "#03286B" }}
        >
          <div className="col-12">
            <Button
              as={Link}
              className="salesButton mb-4 noMb"
              to="https://book.atlantisadventures.com/details/151037/atlantis-submarine-tour-maui/?promo=travelagent"
            >
              MAUI SUBMARINES
            </Button>

            <Button
              as={Link}
              className="salesButton"
              to="https://book.atlantisadventures.com/details/154563/atlantis-submarine-tour-kona/?promo=travelagent"
            >
              KONA SUBMARINES
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TravelAgent;
