import { Link /*useLocation*/ } from "react-router-dom";
import collage from "../images/footerStrip.jpg";
import logo from "../images/atlantis-logo.png";
// import hea from "../images/hea.png";
import years from "../images/35years.png";

function Footer() {
  return (
    <>
      <div className="container mw-100 p-0" style={{ backgroundColor: "#eee" }}>
        <div className="text-center text-light py-5">
          <p className="mb-0 fw-semibold fs-2 p-0">
            <a
              className="text-decoration-none fw-bold"
              style={{ color: "#03286B" }}
              href="tel:18003810237"
            >
              QUESTIONS?
              <br />
              1(800)381-0237
            </a>
          </p>
        </div>
        <img
          src={collage}
          alt="footer collage"
          className="p-0 w-100 align-bottom"
        />
      </div>

      <div className="container footerBG mw-100 footerLinks text-light text-nowrap">
        <div className="row justify-content-center m-0 p-0 ms-sm-4 ps-sm-4 ms-md-0 ps-md-0">
          <div className="border-end border-light border-opacity-75 d-flex justify-content-center mb-5 mb-xl-0 p-0 me-0 me-lg-6 departCol me-sm-4 pe-sm-4">
            <div className="me-lg-6 text-center">
              <Link to="/">
                <img src={logo} alt="logo" className="w-75" />
              </Link>
              <img
                src={years}
                alt="years"
                className="d-block m-auto mt-5"
                style={{ width: "66%" }}
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-start justify-content-md-center mb-5 mb-xl-0 p-0 me-0 me-lg-6 ps-lg-5 majesticCol">
            <div>
              <Link to="/submarines">
                <h5 className="fw-bold text-nowrap">ATLANTIS SUBMARINES</h5>
              </Link>
              <Link to="/waikiki">Waikiki Submarine</Link>
              <br />
              <Link to="/maui">Maui Submarine</Link>
              <br />
              <Link to="/kona">Kona Submarine</Link>
            </div>
            <div className="mt-5">
              <a href="https://www.majestichawaii.com">
                <h5 className="fw-bold text-nowrap">MAJESTIC CRUISES</h5>
              </a>
              <a href="https://www.majestichawaii.com">
                Waikiki Sunset Cocktail Cruise
              </a>
              <br />
              <a href="https://www.majestichawaii.com/friday-fireworks-cocktail-cruise">
                Friday Fireworks & Cocktail Cruise
              </a>
              <br />
              <a href="https://www.majestichawaii.com/whalewatch">
                Whale Watch Cruise
              </a>
              <br />
              <a href="https://www.majestichawaii.com/boat-charters">
                Group Events & Charters
              </a>
            </div>
          </div>

          <div className="d-flex justify-content-start justify-content-md-center mb-5 mb-md-0 p-0 me-0 me-lg-6 exploreCol">
            <div>
              <h5 className="fw-bold">EXPLORE</h5>
              <Link to="/kamaaina">Kama'aina Discounts & Specials</Link>
              <br />
              <Link to="/travel-agent">Travel Agent Portal</Link>
              <br />
              <Link to="/employment">Employment Opportunities</Link>
              <br />
              <Link to="/about">About Us</Link>
              <br />
              <Link to="/our-mission">Our Mission</Link>
              <br />
              <Link to="/contact">Contact Us</Link>
              <br />
              <a href="https://www.atlantissubmarines.com">Global Site</a>
              <br />
              <Link to="/narration">Narration Download</Link>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-start p-0 me-0 socialCol ps-lg-5 ps-xl-0">
            <div>
              <h5 className="fw-bold">SOCIAL</h5>
              <Link to="https://facebook.com/atlantishawaii">Facebook</Link>
              <br />
              <Link to="https://instagram.com/atlantishawaii">Instagram</Link>
            </div>
            {/* <div className="mt-5 text-center text-md-start me-0 pe-0 me-sm-4 pe-sm-4 me-md-0 pe-md-0">
              <a href="https://www.sustainabletourismhawaii.org/">
                <img src={hea} alt="hea" className="heaLogo" />
              </a>
            </div> */}
          </div>
        </div>
        <div className="row text-center pt-5 text-wrap">
          <div className="d-flex col-12 justify-content-center">
            Copyright © 2024 Atlantis Adventures LLC. All rights reserved.
          </div>
          <div className="col-12">
            <Link to="/privacy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
