import { Helmet } from "react-helmet";
import hero from "../images/aboutHero.jpg";

function Privacy() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Privacy Policy</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row">
          <img src={hero} className="p-0 aboutHero" alt="hero" />
        </div>
        <div
          className="row bg-light text-start justify-content-center"
          style={{ color: "#03286B" }}
        >
          <div className="col-12 col-md-10 col-xl-7 py-5">
            <p className="fs-2 fw-bold text-center">
              Atlantis Adventures, LLC. Privacy Policy
            </p>
            <p>
              Here at Atlantis Adventures (www.atlantisadventures.com) we know
              that privacy is important to you and that you care how information
              about you is used and shared. We appreciate your trust that we
              will handle your information carefully and sensibly. This notice
              describes our privacy policy. By visiting Atlantis Adventures, you
              are accepting the practices described in this Privacy Notice.
            </p>
            <p className="fs-4 mb-1 fw-bold">
              About the information we collect...
            </p>
            <p>
              When you visit Atlantis Adventures the information we collect from
              our customers helps us server you better and to continually
              improve both our online and offline product and service offerings.
              We gather information in several ways including: Customer provided
              information: When you reserve an Atlantis Adventures Tour Package,
              we ask for certain basic information required to make your
              purchase including your name, billing address, telephone number,
              email address, payment information, and related travel itinerary
              details to help us server you better. If you choose to pay online
              and with your credit card, a portion of this information is also
              supplied to the credit card company, together with your credit
              card number, for purposes of processing the transaction. Atlantis
              Adventures and its affiliates bear no responsibility for the
              transfer of information by unrelated third parties. This includes
              but is not limited to your credit card issuer and credit reporting
              authorities. Automated electronic information: When you visit
              Atlantis Adventures online we keep track of our customer
              purchases, traffic patterns and related site statistics inside our
              website to help us shape our online progress. Rest assured that
              the only information we collect from your web browser is solely
              based on your activities on our network of websites. We do not
              track your browsing habits as they reflect other sites on the
              Internet. From time to time, we may randomly contact customers
              about participating in user surveys about our website(s). As we do
              not outsource any of these activities you will only be contacted
              by people directly affiliated with Atlantis Adventures, Inc. and
              it's immediate affiliates. These surveys are optional. The
              responses we gather help us make your online shopping experience
              even better.
            </p>
            <p className="fs-4 mb-1 fw-bold">
              What do we do with the information we collect...
            </p>
            <p>
              Customer information is an important part of our business and we
              use it to help us provide better services for you. However, we are
              not in the business of selling your information. We only share our
              customer information to our direct subsidiary operations.
            </p>
            <p className="fs-4 mb-1 fw-bold">Your transactions are secure...</p>
            <p>
              Just as we protect your personal information, we also go to great
              lengths to protect your financial information using
              state-of-the-art encryption technology to safeguard your online
              transactions. Our site is protected using the Secure Socket Layer
              (SSL) of your web browser, which is fully supported by Internet
              Explorer, Firefox, Safari and Chrome web browsers. Your
              information, including your credit card details, is encrypted and
              cannot be read as it travels over the internet.
            </p>
            <p className="fs-4 mb-1 fw-bold">A few notes about cookies...</p>
            <p>
              Cookies you ask? Yes, cookies, but not the enjoyable baked kind.
              Cookies as they pertain to the internet are small pieces of
              information that our servers send through your web browser to your
              hard drive. A cookie lets our system recognize you and the current
              purchases you are making. Most web browsers are set to accept
              cookies automatically, but this setting can be changed in almost
              all cases. See your web browser's “help section” to learn more
              about this setting. To help facilitate your online purchases your
              browser must be set to “accept” cookies for our site
              (www.atlantisadventures.com)
            </p>
            <p className="fs-4 mb-1 fw-bold">
              Who has access to my information...
            </p>
            <p>
              Unlike most online operations, we do not use outside companies to
              help facilitate our operations. Orders are accepted, processed and
              fulfilled in our own group of companies. We will only release your
              personal information to the proper persons when we believe, in
              good faith, that we are required to comply with a law or legal
              process, enforce our agreements, or protect the rights, property
              or safety of our operations or other persons or entities.
            </p>
            <p className="fs-4 mb-1 fw-bold">Postal/email addresses...</p>
            <p>
              We are not in the business of selling your information. We only
              share our customer information to our direct subsidiary
              operations. You have control over how you are contacted from our
              company. We occasionally send new product announcements via postal
              mail or special offers to your email account. You'll always have
              the option to request that your name be removed from our mailing
              lists. To have your information removed from our lists, please
              contact our Customer Service Department at
              administrator@atlantisadventures.com
            </p>
            <p className="fs-4 mb-1 fw-bold">Children</p>
            <p>
              While many of the tours we offer are accessible to children, our
              website is intended for use by adults. We sell children's tours
              for purchase and enjoyed while accompanied by adults. If you are
              under 18, you may use the Atlantis Adventures website only with
              the involvement of a parent or guardian. Additionally, we do not
              knowingly collect information from children under 13 years of age.
              If you believe your child has provided information to our site,
              please contact our Customer Service Department at
              administrator@atlantisadventures.com
            </p>
            <p className="fs-4 mb-1 fw-bold">Acceptance of terms...</p>
            <p>
              By using www.atlantisadventures.com and its online affiliate
              domains and/or provide use with your personal information, you are
              accepting the privacy practices described in this document. We may
              update our Privacy Policy from time to time. Please check back
              here occasionally for any policy changes. You will always find the
              latest version posted here. Please note that because a customer
              information database is customarily considered a business asset,
              www.atlantisadventures.com and its affiliates shall have the right
              to transfer it in a merger or sale of some or all of its company
              or in an action for bankruptcy. Your information will continue to
              be treated in accordance with this Privacy Policy by the acquiring
              or merged company unless you are notified and provided an
              opportunity to opt out of any changes.
            </p>
            <p className="fs-4 mb-1 fw-bold">
              Notice of disclaimer to all users...
            </p>
            <p>
              Atlantis Adventures, LLC. is a Canadian corporation incorporated
              in the province of British Columbia and observes generally
              accepted Canadian practices with respect to the privacy of data
              collected by it. Atlantis Adventures does not represent or warrant
              that its data collection practices meet the standards of European
              Union Member States, or any other jurisdiction outside of Canada.
              By your use of the site to order merchandise you agree that you
              have read and agree to British Columbia jurisdiction over any
              disputes that may arise in connection with it.
            </p>
            <p className="fs-4 mb-1 fw-bold">Questions about our policy...</p>
            <p>
              If you have any questions or comments about our Privacy Policy,
              please send us an email at administrator@atlantisadventures.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
