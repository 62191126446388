import ReviewCarousel from "../components/ReviewCarousel";
import wavyBorder from "../images/wavy.png";

export default function ReviewSection({ reviewsList }) {
  return (
    <div className="container mw-100 p-0 position-relative z-2 overflow-hidden">
      <img src={wavyBorder} alt="wavy" className="h-100 align-bottom" />
      <div className="row justify-content-center align-items-center text-center reviewContainer position-relative">
        <ReviewCarousel reviewsList={reviewsList} />
      </div>
    </div>
  );
}
