import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import homeVideo from "../images/submarinesHome.mp4";
import subShift1 from "../images/subShift1.jpg";
import subShift2 from "../images/subShift2.jpg";
import subShift3 from "../images/subShift3.jpg";
import subLocations from "../images/subLocations.png";
import subsLocationsLogo from "../images/subsLocations.png";
import VideoFrame from "../components/VideoFrame";
import ReviewSection from "../components/ReviewSection";

function Submarines() {
  const reviewsList = [
    {
      title: "Loved the underwater experience",
      review:
        "“My whole family loved the underwater experience with beautiful sea life and amazing narrator giving awesome information about sea life and other things we saw during our submarine ride. Great for young kids.”",
      person: "Paramjot S.",
    },
    {
      title: "Amazing",
      review:
        "“Well worth the time and $$! Saw soooo much, sharks, turtles, rays! Highly recommend!!!”",
      person: "Kathryn S.",
    },
    {
      title: "Bucket list item!",
      review:
        "“This adventure was everything we thought it would be. We saw bunches of tropical fish and a nurse shark. Plenty to see and excellent tour guide!”",
      person: "Kevin F.",
    },
    {
      title: "So Cool",
      review:
        "“Everything was awesome! Seeing fish all the while staying dry. Ideal for non swimming or snorkeling people!”",
      person: "Randall H.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Hawaii</title>
      </Helmet>
      <div className="container mw-100">
        <VideoFrame video={homeVideo} img={subsLocationsLogo} />
        <div
          className="row bg-light text-center justify-content-center"
          style={{ color: "#03286B" }}
        >
          <div className="col-12 col-md-10 col-xl-9 col-xxl-6 py-5">
            <p className="fs-2 fw-bold mb-4">EXPLORE OUR HAWAII</p>
            <p className="mb-4">
              Atlantis Submarines has been sharing the magnificence of Hawaii's
              undersea world since 1988 using the environmentally safe
              submarines that are battery powered, emit no pollutants, and
              quietly move through the water. It's the ultimate in sustainable
              tourism.
            </p>
            <p className="mb-0">
              At our Waikiki and Maui dive sites, we've taken on the
              responsibility of installing artificial reefs to create
              self-sustaining habitats for fish and marine life to flourish.
              Areas once barren of life are now vibrant. Our Kona dive site is
              highlighted by a pristine 25-acre coral reef garden that is a
              natural safe haven for fish, plant life and organisms to thrive.
              Atlantis closely observes the abundance of activity at this
              treasured site but always leaves it untouched.
            </p>
          </div>
        </div>
      </div>
      <div className="dynamicContainer">
        <div className="dynamicTile-subs tileShift">
          <img
            src={subShift1}
            alt="Submarine 1"
            className="w-100 object-fit-cover"
          />
          <div className="px-md-4 px-lg-5 pt-5">
            <h2 className="text-white fw-semibold fs-4">WAIKIKI</h2>
            <p className="text-white mb-0">
              Located within view of famous Leahi (Diamond Head), the Atlantis
              dive site is frequented by green sea turtles, sharks, stingrays,
              yellow tangs, eels, and many other species of underwater marine
              life.
            </p>
            <div className="subsSlidesHeight" />
            <Button
              as={Link}
              to="https://book.atlantisadventures.com/details/151625/atlantis-submarine-tour-in-waikiki"
              className="booknow-cta text-nowrap navFontSize slidesButton"
            >
              BOOK NOW
            </Button>
          </div>
        </div>
        <div className="dynamicTile-subs2 tileShift">
          <img
            src={subShift2}
            alt="Submarine 2"
            className="w-100 object-fit-cover"
          />
          <div className="px-md-4 px-lg-5 pt-5">
            <h2 className="text-white fw-semibold fs-4">MAUI</h2>
            <p className="text-white mb-0">
              A highlight of this submarine adventure is the chance to see a
              sunken ship called the Carthaginian, which serves as an artificial
              reef to help enhance Maui’s marine environment.
            </p>
            <div className="subsSlidesHeight" />
            <Button
              as={Link}
              to="https://book.atlantisadventures.com/details/151037/atlantis-submarine-tour-maui"
              className="booknow-cta text-nowrap navFontSize slidesButton"
            >
              BOOK NOW
            </Button>
          </div>
        </div>
        <div className="dynamicTile-subs3 tileShift">
          <img
            src={subShift3}
            alt="Submarine 3"
            className="w-100 object-fit-cover"
          />
          <div className="px-md-4 px-lg-5 pt-5">
            <h2 className="text-white fw-semibold fs-4">KONA</h2>
            <p className="text-white mb-0">
              An unforgettable journey aboard an Atlantis 48-passenger
              submarine, as featured in National Geographic television specials.
              Guests will explore a 25-acre natural coral reef and its marine
              inhabitants along with fascinating sunken ships.
            </p>
            <div className="subsSlidesHeight" />
            <Button
              as={Link}
              to="https://book.atlantisadventures.com/details/154563/atlantis-submarine-tour-kona"
              className="booknow-cta text-nowrap navFontSize slidesButton"
            >
              BOOK NOW
            </Button>
          </div>
        </div>
      </div>
      <div className="dynamicContainer-mobile">
        <div className="dynamicTile-mobile noShift">
          <img
            src={subShift1}
            alt="Submarine 1"
            className="w-100 object-fit-cover img50"
          />
          <div className="p-4 py-5">
            <h2 className="text-white fw-semibold fs-4">WAIKIKI</h2>
            <p className="text-white mb-4">
              Located within view of famous Leahi (Diamond Head), the Atlantis
              dive site is frequented by green sea turtles, sharks, stingrays,
              yellow tangs, eels, and many other species of underwater marine
              life.
            </p>
            <Button
              as={Link}
              to="https://book.atlantisadventures.com/details/151625/atlantis-submarine-tour-in-waikiki"
              className="booknow-cta text-nowrap navFontSize"
            >
              BOOK NOW
            </Button>
          </div>
        </div>
        <div className="dynamicTile-mobile noShift">
          <img
            src={subShift2}
            alt="Submarine 2"
            className="w-100 object-fit-cover img50"
          />
          <div className="p-4 py-5">
            <h2 className="text-white fw-semibold fs-4">MAUI</h2>
            <p className="text-white mb-4">
              A highlight of this submarine adventure is the chance to see a
              sunken ship called the Carthaginian, which serves as an artificial
              reef to help enhance Maui’s marine environment.
            </p>
            <Button
              as={Link}
              to="https://book.atlantisadventures.com/details/151037/atlantis-submarine-tour-maui"
              className="booknow-cta text-nowrap navFontSize"
            >
              BOOK NOW
            </Button>
          </div>
        </div>
        <div className="dynamicTile-mobile noShift">
          <img
            src={subShift3}
            alt="Submarine 3"
            className="w-100 object-fit-cover img50"
          />
          <div className="p-4 py-5">
            <h2 className="text-white fw-semibold fs-4">KONA</h2>
            <p className="text-white mb-4">
              An unforgettable journey aboard an Atlantis 48-passenger
              submarine, as featured in National Geographic television specials.
              Guests will explore a 25-acre natural coral reef and its marine
              inhabitants along with fascinating sunken ships.
            </p>
            <Button
              as={Link}
              to="https://book.atlantisadventures.com/details/154563/atlantis-submarine-tour-kona"
              className="booknow-cta text-nowrap navFontSize"
            >
              BOOK NOW
            </Button>
          </div>
        </div>
      </div>
      <div className="locationsBG shiftUp text-center py-4 py-md-5 px-3">
        <img src={subLocations} alt="locations" className="mw-100" />
      </div>
      <ReviewSection reviewsList={reviewsList} />
    </>
  );
}

export default Submarines;
