import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import homeVideo from "../images/home.mp4";
import homeSub from "../images/homeSub.jpg";
import homeMajestic from "../images/homeMajestic.jpg";
import pane1 from "../images/pane1.jpg";
import pane2 from "../images/pane2.jpg";
import pane3 from "../images/pane3.jpg";
import VideoFrame from "../components/VideoFrame";
import ReviewSection from "../components/ReviewSection";

function Home() {
  const reviewsList = [
    {
      title: "Loved the underwater experience",
      review:
        "“My whole family loved the underwater experience with beautiful sea life and an amazing narrator giving awesome information about sea life and other things we saw during our submarine ride. Great for young kids.”",
      person: "Paramjot S.",
      tour: "Atlantis Submarines",
    },
    {
      title: "Amazing",
      review:
        "“Well worth the time and $$! Saw soooo much, sharks, turtles, rays! Highly recommend!!!”",
      person: "Kathryn S.",
      tour: "Atlantis Submarines",
    },
    {
      title: "Clean, comfortable and great music",
      review:
        "“We loved this cruise. Great live music, complimentary cocktails and a friendly and attentive crew. Wonderful sunset views as well!”",
      person: "Sachin G.",
      tour: "Majestic",
    },
    {
      title: "Totally awesome",
      review:
        "“Checking in was a breeze. Everyone was very pleasant. Service was super. The Fireworks Display was magnificent! This was one of our favorite tour activities. We hope to do it again on a future vacation!!!”",
      person: "Daniel C.",
      tour: "Majestic",
    },
    {
      title: "Bucket list item!",
      review:
        "“This adventure was everything we thought it would be. We saw bunches of tropical fish and a nurse shark. Plenty to see and excellent tour guide!”",
      person: "Kevin F.",
      tour: "Atlantis Submarines",
    },
    {
      title: "Excellent experience!",
      review:
        "“We had a great time and the staff was also excellent. Very professional and considerate.”",
      person: "Kalani W.",
      tour: "Majestic",
    },
    {
      title: "So Cool",
      review:
        "“Everything was awesome! Seeing fish all the while staying dry. Ideal for non swimming or snorkeling people!”",
      person: "Randall H.",
      tour: "Atlantis Submarines",
    },
    {
      title: "Sunset cruise",
      review:
        "“Fair price, very very comfortable, plenty of room to roam around, good drinks, great music, and most importantly, great great great staff. Super friendly, funny and helpful, and with big smiles. Wife and I wouldn't hesitate to do it again or recommend it to all our friends.”",
      person: "Daniel C.",
      tour: "Majestic",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Atlantis Submarine Adventures Hawaii | Majestic by Atlantis Cruises
        </title>
      </Helmet>
      <div className="container mw-100">
        <VideoFrame video={homeVideo} overlay="EXPLORE OUR HAWAII" />
      </div>
      <div className="dynamicContainer shiftUp">
        <div className="dynamicTile tileShift">
          <img
            src={homeSub}
            alt="Submarine"
            className="w-100 object-fit-cover"
          />
          <div className="px-md-4 px-lg-5 pt-5">
            <h2 className="text-white fw-semibold fs-4">ATLANTIS SUBMARINES</h2>
            <p className="text-white mb-0">
              Explore Hawaii’s depths over 100 feet below the surface and “swim”
              amidst schools of beautiful fish in the world’s most
              technologically advanced, Coast Guard-approved passenger
              submarines. Glide in air-conditioned comfort in our
              battery-powered, eco-friendly subs.
            </p>
            <div className="subsSlidesHeight" />
            <Button
              as={Link}
              to="/submarines"
              className="booknow-cta text-nowrap navFontSize slidesButton"
            >
              LEARN MORE
            </Button>
          </div>
        </div>
        <div className="dynamicTile2 tileShift">
          <img
            src={homeMajestic}
            alt="Majestic"
            className="w-100 object-fit-cover"
          />
          <div className="px-md-4 px-lg-5 pt-5">
            <h2 className="fw-semibold fs-4" style={{ color: "#03286B" }}>
              MAJESTIC BY ATLANTIS CRUISES
            </h2>
            <p className="mb-0" style={{ color: "#03286B" }}>
              Hawaii’s only relaxed yacht cruise experience offers Waikiki
              Sunset Cocktail Cruises, Friday Fireworks & Cocktail Cruises, and
              Seasonal Whale Watch Cruises in waters off Oahu. Create
              unforgettable moments as you savor coastline views on our spacious
              open-air top deck or enjoy refreshing cocktails in our
              air-conditioned full-service bar & lounge.
            </p>
            <div className="subsSlidesHeight" />
            <Button
              as={Link}
              to="https://www.majestichawaii.com"
              className="booknow-cta text-nowrap navFontSize slidesButton"
            >
              LEARN MORE
            </Button>
          </div>
        </div>
      </div>
      <div className="dynamicContainer-mobile shiftUp">
        <div className="dynamicTile-mobile noShift">
          <img
            src={homeSub}
            alt="Submarine"
            className="w-100 object-fit-cover"
          />
          <div className="p-4 py-5">
            <h2 className="text-white fw-semibold fs-4">ATLANTIS SUBMARINES</h2>
            <p className="text-white mb-4">
              Explore Hawaii’s depths over 100 feet below the surface and “swim”
              amidst schools of beautiful fish in the world’s most
              technologically advanced, Coast Guard-approved passenger
              submarines. Glide in air-conditioned comfort in our
              battery-powered, eco-friendly subs.
            </p>
            <Button
              as={Link}
              to="/submarines"
              className="booknow-cta text-nowrap navFontSize"
            >
              LEARN MORE
            </Button>
          </div>
        </div>
        <div className="dynamicTile2-mobile noShift">
          <img
            src={homeMajestic}
            alt="Majestic"
            className="w-100 object-fit-cover"
          />
          <div className="p-4 py-5">
            <h2 className="fw-semibold fs-4" style={{ color: "#03286B" }}>
              MAJESTIC BY ATLANTIS CRUISES
            </h2>
            <p className="mb-4" style={{ color: "#03286B" }}>
              Hawaii’s only relaxed yacht cruise experience offers Waikiki
              Sunset Cocktail Cruises, Friday Fireworks & Cocktail Cruises, and
              Seasonal Whale Watch Cruises in waters off Oahu. Create
              unforgettable moments as you savor coastline views on our spacious
              open-air top deck or enjoy refreshing cocktails in our
              air-conditioned full-service bar & lounge.
            </p>
            <Button
              as={Link}
              to="https://www.majestichawaii.com"
              className="booknow-cta text-nowrap navFontSize"
            >
              LEARN MORE
            </Button>
          </div>
        </div>
      </div>
      <ReviewSection reviewsList={reviewsList} />
      <div className="container mw-100">
        <div className="row">
          <div className="col-4 p-0">
            <img src={pane1} className="w-100" alt="pane1" />
          </div>
          <div className="col-4 p-0">
            <img src={pane2} className="w-100" alt="pane2" />
          </div>
          <div className="col-4 p-0">
            <img src={pane3} className="w-100" alt="pane3" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
