import heroWaikiki from "../images/1 Waikiki.jpg";
import leftWaikiki from "../images/2 Waikiki.jpg";
import bottomWaikiki from "../images/3 Waikiki.jpg";
import heroMaui from "../images/1 Maui.jpg";
import leftMaui from "../images/2 Maui.jpg";
import bottomMaui from "../images/3 Maui.jpg";
import heroKona from "../images/1 Kona.jpg";
import leftKona from "../images/2 Kona.jpg";
import bottomKona from "../images/3 Kona.jpg";
import waikikiIcon1 from "../images/waikikiIcon (1).jpg";
import waikikiIcon2 from "../images/waikikiIcon (2).jpg";
import waikikiIcon3 from "../images/waikikiIcon (3).jpg";
import mauiIcon1 from "../images/mauiIcon (1).jpg";
import mauiIcon2 from "../images/mauiIcon (2).jpg";
import mauiIcon3 from "../images/mauiIcon (3).jpg";
import konaIcon1 from "../images/konaIcon (1).jpg";
import konaIcon2 from "../images/konaIcon (2).jpg";
import konaIcon3 from "../images/konaIcon (3).jpg";

export const waikikiProps = {
  title: "Waikiki",
  overlay: "WAIKIKI",
  hero: heroWaikiki,
  left: leftWaikiki,
  icon1: waikikiIcon1,
  icon2: waikikiIcon2,
  icon3: waikikiIcon3,
  bottom: bottomWaikiki,
  h3: "Climb aboard Atlantis XIV, the world’s largest hi-tech passenger submarine.",
  p1: "Experience an underwater adventure that's fun for the whole family while diving 100 feet below the sea in our eco-friendly, battery-powered, Coast Guard-approved submarine. Discover Waikiki’s turtles, sharks, and vibrant sea life while gliding past artificial reefs like sunken ships, an airplane hull, and other thrilling marine life habitats. Atlantis takes you beyond the classroom, as you learn about Hawaii’s precious marine environment in our spacious, air-conditioned submarine.",
  icon1Text:
    "Artificial reefs transform barren areas into thriving marine habitats.",
  icon2Text: "The dive site offers stunning views of iconic Diamond Head.",
  icon3Text:
    "Encounter diverse marine life, including turtles, sharks, and stingrays.",
  link: "https://book.atlantisadventures.com/details/151625/atlantis-submarine-tour-in-waikiki",
};

export const mauiProps = {
  title: "Maui",
  overlay: "MAUI",
  hero: heroMaui,
  left: leftMaui,
  icon1: mauiIcon1,
  icon2: mauiIcon2,
  icon3: mauiIcon3,
  bottom: bottomMaui,
  h3: "Climb aboard Maui’s hi-tech battery powered 48-passenger submarine.",
  p1: "Marvel at Maui’s unique underwater paradise while descending over 100 feet below the surface to see coral reefs, vibrant fish, and other fascinating marine wonders. A highlight of this submarine adventure is the opportunity to see a sunken ship known as The Carthaginian, which is a replica of a 19th-century supply vessel that now serves as a self-sustaining marine life habitat. The Carthaginian has now evolved into a world-famous scuba diving site.",
  icon1Text:
    "The Carthaginian was carefully sunk by Atlantis to create a self-sustaining habitat for marine life.",
  icon2Text:
    "Explore Maui’s undersea world in our air-conditioned, Coast Guard-approved sub.",
  icon3Text:
    "Learn about Maui’s precious marine environment from our tour narrator",
  link: "https://book.atlantisadventures.com/details/151037/atlantis-submarine-tour-maui",
};

export const konaProps = {
  title: "Kona",
  overlay: "KONA",
  hero: heroKona,
  left: leftKona,
  icon1: konaIcon1,
  icon2: konaIcon2,
  icon3: konaIcon3,
  bottom: bottomKona,
  h3: "Climb aboard Kona’s hi-tech battery powered submarine.",
  p1: "Discover the magic of Kona’s undersea world while diving over 100 feet beneath the sea in our eco-friendly, 48-passenger submarine. Learn about Kona’s marine mysteries in the air-conditioned comfort of our Coast Guard-approved submarine. The experience is like scuba diving without getting wet!",
  icon1Text:
    "The pristine coral reef spans 25 untouched acres of natural beauty.",
  icon2Text:
    "This reef is a thriving haven for diverse marine plants and animals.",
  icon3Text: "Discover the hidden wonders of Kona’s undersea world.",
  link: "https://book.atlantisadventures.com/details/154563/atlantis-submarine-tour-kona",
};
