import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import hero from "../images/highlights.jpg";

function Error() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | 404</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row position-relative vh-100">
          <img src={hero} className="p-0 w-100 object-fit-cover" alt="hero" />
          <div className="position-absolute top-50 start-50 translate-middle fw-semibold w-75 text-center">
            <p
              className="text-light italicFont"
              style={{ fontSize: "calc(2rem + 1.5vw)" }}
            >
              Page Not Found!
            </p>
            <Button variant="light" size="lg" as={Link} to="/">
              Home
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error;
